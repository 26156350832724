import theme from '../themes/theme'

const [phone, tablet, desktop] = theme.breakpoints

const media = {
  phone: `(min-width: ${phone})`,
  tablet: `(min-width: ${tablet})`,
  desktop: `(min-width: ${desktop})`,
}

export const customMedia = {
  inner: `(min-width: ${theme.sizes.inner})`,
  desktopLarge: `(min-width: 105rem)`,
  maxPhone: `(max-width: ${parseInt(phone) - 1}px)`,
  maxTablet: `(max-width: ${parseInt(tablet) - 1}px)`,
  fullWidth: `(min-width: 90rem)`,
}

export default media
