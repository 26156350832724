import React, { useState, useEffect } from 'react'

import {
  StyledHeader,
  StyledHeaderInner,
  StyledHeaderLogo,
  StyledHeaderTools,
} from './styles'
import Logo from '../Logo'
import Tools from '../Tools'

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.onscroll = () => {
        const scrollPosition = window.pageYOffset
        scrollPosition > 1 ? setIsScrolled(true) : setIsScrolled(false)
      }
    }
  })

  return (
    <StyledHeader {...{ isScrolled }}>
      <StyledHeaderInner>
        <StyledHeaderLogo>
          <Logo />
        </StyledHeaderLogo>
        <StyledHeaderTools>
          <Tools />
        </StyledHeaderTools>
      </StyledHeaderInner>
    </StyledHeader>
  )
}

export default Header
