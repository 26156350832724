import React from 'react'

import {
  StyledTools,
  StyledToolsInfo,
  StyledToolsDownloadLink,
  StyledToolsContents,
} from './styles'
import { Typo7 } from '../Typography'
import { URL_PATHS } from '../../constants/urlPaths'
import Contents from '../Contents'

const Tools = () => (
  <StyledTools>
    <StyledToolsInfo>
      <Typo7 fontWeight="bold" color="valhalla">
        Annual Report and Financial Statements 2021
      </Typo7>
    </StyledToolsInfo>
    <StyledToolsContents>
      <Contents label="Contents" url={URL_PATHS.REPORT} variant="menu" />
    </StyledToolsContents>
    <StyledToolsDownloadLink>
      <Contents label="Downloads" url={URL_PATHS.DOWNLOADS} variant="download" />
    </StyledToolsDownloadLink>
  </StyledTools>
)

export default Tools
