export const URL_PATHS = {
  HOME: '/',
  ABOUT_US: 'about-us',
  VALUES: 'values',
  PUBLIC_INTEREST: 'public-interest',
  AIMS: 'aims',
  COVID19: 'covid19',
  DOWNLOADS: 'downloads',
  QA: 'qa',
  REPORT: 'report',
}
