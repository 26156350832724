import '../fonts/fonts.css'

export default {
  colors: {
    black: '#000',
    white: '#FFF',
    lightestBlue: '#F0F4F9',
    linkWater: '#E7F2F9',
    lightBlue: '#009FE3',
    navyBlue: '#003F84',
    navyBlue2: '#084891',
    teal: '#009CA7',
    tealDark: '#00869C',
    purple: '#6E3075',
    prupleLight: '#413A74',
    yellow: '#FFFF00',
    green: '#648C2E',
    orange: '#E03F10',
    orangeLight: '#E76A38',
    grey: '#808080',
    valhalla: '#211F5E',
    deepCerulean: '#00869C',
    crete: '#648C2E',
    greenLight: '#538621',
    springGreen: '#769A45',
    eminence: '#6E3075',
    plum: '#682E57',
    plumLight: '#805073',
    tiaMaria: '#E03F10',
  },
  breakpoints: ['768px', '1025px', '1440px'],
  sizes: {
    inner: '1440px',
    headerTopMobile: '4.25rem',
    headerTop: '5rem',
  },
  fontWeights: {
    light: 300,
    book: 400,
    medium: 500,
    bold: 700,
    black: 800,
    ultra: 900,
  },
  fontFamilies: {
    gotham: `'Gotham', sans-serif;`,
  },
  textStyles: {
    heading1: {
      fontSize: ['2.5rem', '3rem', '3.75rem'],
      lineHeight: '1.1',
      fontWeight: 'bold',
      letterSpacing: ['-2px', '-3px'],
    },
    heading2: {
      fontSize: ['1.5rem', '', '2.5rem'],
      lineHeight: '1',
      fontWeight: 'medium',
      letterSpacing: ['-0.13px', '-0.2px'],
    },
    heading3: {
      fontSize: ['1.25rem', '', '1.75rem'],
      lineHeight: '1.43',
      fontWeight: 'light',
      letterSpacing: '-2px',
    },
    heading4: {
      fontSize: ['1.125rem', '', '1.25rem', '1.375rem'],
      letterSpacing: '-1.1px',
    },
    heading5: {
      fontSize: ['1.125rem', '', '1.375rem'],
      letterSpacing: '-1.1px',
    },
    heading6: {
      fontSize: ['1.25rem', '', '2.1875rem'],
      letterSpacing: ['-.1px', '-0.18px'],
    },
    heading7: {
      fontSize: ['1.875rem', '', '2.25rem'],
      letterSpacing: ['-.15px'],
      lineHeight: '1.1',
      fontWeight: 'bold',
    },
    heading8: {
      fontSize: ['2.8125rem', '5rem'],
      lineHeight: '1',
      letterSpacing: ['-2.25px', '-4px'],
    },
    heading9: {
      fontSize: ['4.375rem', '9.375rem'],
      lineHeight: '1',
      letterSpacing: ['-1.62px', '-3.46px'],
    },
    heading10: {
      fontSize: ['2.8125rem', '4.25rem', '6.25rem'],
      lineHeight: '1',
      letterSpacing: ['-1.94px', '', '-4.31px'],
    },
    heading11: {
      fontSize: ['1rem', '1.75rem'],
      lineHeight: '1',
      letterSpacing: ['-0.8px', '-1.4px'],
    },
    heading12: {
      fontSize: ['1.375rem', '2.5rem', '3rem'],
      lineHeight: '1.1',
      letterSpacing: ['-1.1px', '-2.4px'],
    },
    heading13: {
      fontSize: ['1.5625rem', '1.875rem'],
      lineHeight: '1.1',
      letterSpacing: ['-1.25px', '-1.5px'],
    },
    heading14: {
      fontSize: ['1.5625rem'],
      lineHeight: '1.36',
      letterSpacing: ['-0.13px'],
    },
    heading15: {
      fontSize: ['1.5625rem', '2.1875rem'],
      lineHeight: '1.15',
      letterSpacing: ['-0.13px', '-0.18px'],
    },
    heading16: {
      fontSize: ['1rem', '2.1875rem'],
      lineHeight: '1.15',
      letterSpacing: ['-0.08px', '-0.18px'],
    },
    heading17: {
      fontSize: ['1.875rem', '', '3.125rem'],
      lineHeight: '1',
      letterSpacing: '1px',
    },
    typo1: {
      fontSize: '1.25rem',
    },
    typo2: {
      fontSize: ['1rem', '', '1.125rem'],
      letterSpacing: '-0.9px',
    },
    typo3: {
      fontSize: '0.75rem',
    },
    typo4: {
      fontSize: '0.875rem',
      lineHeight: '1.28',
      letterSpacing: '-0.5px',
    },
    typo5: {
      fontSize: ['0.875rem', '1.0625rem'],
      lineHeight: '1.6',
      letterSpacing: ['-0.7px', '-0.85px'],
    },
    typo6: {
      fontSize: '1rem',
      lineHeight: '1.25',
      fontWeight: 'book',
      letterSpacing: '-0.8px',
    },
    // new typo
    typo7: {
      fontSize: ['1.25rem', '1.125rem', '1.25rem'],
      lineHeight: '1.2',
      letterSpacing: '-0.5px',
    },
    typo8: {
      fontSize: '1rem',
      lineHeight: '1',
      letterSpacing: '-0.08px',
    },
    typo9: {
      fontSize: ['1.375rem', '1.125rem', '1.375rem'],
      lineHeight: '1.3',
      letterSpacing: '-1.1px',
    },
    typo10: {
      fontSize: ['1.375rem'],
      lineHeight: '1.3',
      letterSpacing: '-1px',
    },
    typo11: {
      fontSize: ['1.375rem', '2.5rem'],
      lineHeight: '1.2',
      letterSpacing: ['-1.1px', '-2px'],
    },
    typo12: {
      fontSize: ['4.375rem', '6.5rem'],
      lineHeight: '1',
      letterSpacing: ['-1.62px', '-2.31px'],
    },
    typo13: {
      fontSize: ['1.375rem', '3.125rem'],
      lineHeight: '1.2',
      letterSpacing: ['0', '-2.5px'],
    },
    typo14: {
      fontSize: ['1.375rem', '2.5rem'],
      lineHeight: '1.2',
      letterSpacing: ['0', '-2px'],
    },
    typo15: {
      fontSize: ['1.875rem'],
      lineHeight: '1',
      letterSpacing: ['-1.29px'],
    },
    typo16: {
      fontSize: ['1rem', '1.375rem'],
      lineHeight: '1',
      letterSpacing: ['-0.8px', '-1.1px'],
    },
    typo17: {
      fontSize: ['1rem', '1.15rem'],
      lineHeight: '1.3',
      letterSpacing: ['-0.8px', '-1px'],
    },
    typo18: {
      fontSize: ['2rem', '2.5rem', '3.125rem'],
      lineHeight: '1',
      letterSpacing: '-2.5px',
    },
  },
}
