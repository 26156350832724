import React, { useState, useCallback } from 'react'

import NavigationContext from '../contexts/NavigationContext'

const NavigationProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false)

  const handleClick = useCallback(() => {
    setIsVisible(!isVisible)
  }, [isVisible])

  return (
    <NavigationContext.Provider
      value={{ isVisible, setIsVisible, handleClick }}
    >
      {children}
    </NavigationContext.Provider>
  )
}

export default NavigationProvider
