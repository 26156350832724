import React from 'react'
import PropTypes from 'prop-types'

import { StyledSimpleLink, StyledSimpleLinkIcon } from './styles'
import { Heading4, Typo9 } from '../../Typography'
import ArrowCircleIcon from '../../Icons/ArrowCircleIcon'

const SimpleLink = ({ label, url, isUnderlined, iconVariant, typoVariant, onClick }) => (
  <StyledSimpleLink
    isUnderlined={isUnderlined}
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    onClick={onClick}
  >
    {iconVariant && iconVariant === 'arrow-right' ? (
      <StyledSimpleLinkIcon isRight>
        <ArrowCircleIcon />
      </StyledSimpleLinkIcon>
    ) : (
      ''
    )}
    {!typoVariant || typoVariant === 'typo9' ? (
      <Typo9 fontWeight="bold">{label}</Typo9>
    ) : (
      ''
    )}
    {typoVariant && typoVariant === 'heading4' ? (
      <Heading4 fontWeight="medium">{label}</Heading4>
    ) : (
      ''
    )}
    {iconVariant && iconVariant === 'arrow-top' ? (
      <StyledSimpleLinkIcon>
        <ArrowCircleIcon />
      </StyledSimpleLinkIcon>
    ) : (
      ''
    )}
  </StyledSimpleLink>
)

export default SimpleLink

SimpleLink.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string,
  isUnderlined: PropTypes.bool,
  iconVariant: PropTypes.string,
  typoVariant: PropTypes.string,
  onClick: PropTypes.func,
}
