import styled from 'styled-components'
import {
  fontSize,
  color,
  fontWeight,
  textAlign,
  letterSpacing,
  lineHeight,
  system,
} from 'styled-system'

const StyledDynamicComponent = styled.p`
  ${fontSize}
  ${color}
  ${fontWeight}
  ${textAlign}
  ${letterSpacing}
  ${lineHeight}
  ${system({
    textTransform: true,
    textDecoration: true,
  })}
`

export default StyledDynamicComponent
