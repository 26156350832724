import styled from 'styled-components'

import media from '../../common/MediaQueries'
import PicSvg from '../../assets/logo.svg'
import PicPng from '../../assets/logo.png'

const StyledLogo = styled.a`
  display: block;
`

const StyledLogoIcon = styled.span`
  display: block;
  width: 5.25rem;
  height: 2.5rem;
  background-image: url(${PicSvg});
  background-size: 5.25rem 2.5rem;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    background-image: url(${PicPng});
  }

  @media ${media.phone} {
    width: 5.25rem;
    height: 2.5rem;
    background-size: 5.25rem 2.5rem;
  }
`

const StyledLogoLabel = styled.span`
  display: none;
`

export { StyledLogo, StyledLogoLabel, StyledLogoIcon }
