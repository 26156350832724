import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import Inner from '../Inner'
import media, { customMedia } from '../../common/MediaQueries'
import { transition } from '../../common/mixins'

const StyledHeaderTools = styled.div`
  width: 100%;
  padding-left: 7.25rem;
  @media ${customMedia.maxPhone} {
    display: none;
  }
`

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  height: ${({ theme }) => theme.sizes.headerTopMobile};

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: inherit;
    background: ${({ theme }) => rgba(theme.colors.white, 0.9)};
    z-index: 1;
    ${transition}
  }

  ${({ isOpened }) =>
    isOpened &&
    css`
      background: ${({ theme }) => rgba(theme.colors.lightestBlue, 0.9)};

      &::before {
        background: ${({ theme }) => rgba(theme.colors.lightestBlue, 0.9)};
      }
    `}

  @media ${media.phone} {
    height: ${({ theme }) => theme.sizes.headerTop};

    &::after {
      position: absolute;
      display: block;
      content: '';
      width: 100%;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.valhalla};
      bottom: 0;
      z-index: 2;
      opacity: 0;
      ${transition}
    }

    ${({ isScrolled }) =>
      isScrolled &&
      css`
      &::after {
        opacity: 1;
      }
      `};
  }
`

const StyledHeaderInner = styled(Inner)`
  position: relative;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  z-index: 1;
  height: inherit;

  @media ${media.phone} {
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${({ theme }) => theme.sizes.headerTop};
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    z-index: 3;
  }
`

const StyledHeaderLogo = styled.div`
  margin-bottom: 1.25rem;

  @media ${media.phone} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0.9375rem;
    width: 5.25rem;
    margin-bottom: 0;
  }

  @media ${media.tablet} {
    left: 1.875rem;
  }
`

export {
  StyledHeader,
  StyledHeaderInner,
  StyledHeaderLogo,
  StyledHeaderTools,
}
