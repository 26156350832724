import styled from 'styled-components'
import { maxWidth } from 'styled-system'

import media from '../../common/MediaQueries'

const Inner = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  max-width: ${({ theme }) => theme.sizes.inner};
  ${maxWidth}

  @media ${media.tablet} {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
`

export default Inner
