import React from 'react'

import { IconWrapper } from '../../common/mixins'

const MenuIcon = () => (
  <IconWrapper>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36px"
      height="35px"
      viewBox="0 0 36 35"
      version="1.1"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Navigation" transform="translate(-1134.000000, -23.000000)">
          <g id="Group-10" transform="translate(1134.424411, 23.000000)">
            <path
              d="M8.98751734,15 L25.963939,15 L8.98751734,15 Z"
              id="Fill-1"
              fill="#FFFFFF"
            />
            <line
              x1="10.9847434"
              y1="12.1777108"
              x2="26.962552"
              y2="12.1777108"
              id="Stroke-2"
              stroke="currentColor"
              strokeLinecap="round"
            />
            <polyline
              id="Stroke-5"
              stroke="currentColor"
              strokeLinecap="round"
              points="10.9847434 17.9995592 14.0978054 17.9995592 26.962552 17.9995592"
            />
            <path
              d="M8.98751734,24 L25.963939,24 L8.98751734,24 Z"
              id="Fill-7"
              fill="#FFFFFF"
            />
            <line
              x1="10.9847434"
              y1="24.1768293"
              x2="26.962552"
              y2="24.1768293"
              id="Stroke-8"
              stroke="currentColor"
              strokeLinecap="round"
            />
            <path
              d="M8.98751734,14.5001031 C8.98751734,15.3286137 8.31685626,16 7.48949482,16 C6.6623393,16 5.99167822,15.3286137 5.99167822,14.5001031 C5.99167822,13.6715925 6.6623393,13 7.48949482,13 C8.31685626,13 8.98751734,13.6715925 8.98751734,14.5001031"
              id="Fill-10"
              fill="#FFFFFF"
            />
            <path
              d="M8.98751734,12.5001031 C8.98751734,13.3286137 8.31685626,14 7.48949482,14 C6.6623393,14 5.99167822,13.3286137 5.99167822,12.5001031 C5.99167822,11.6715925 6.6623393,11 7.48949482,11 C8.31685626,11 8.98751734,11.6715925 8.98751734,12.5001031 Z"
              id="Stroke-12"
              stroke="currentColor"
            />
            <path
              d="M8.98751734,18.5 C8.98751734,19.3284536 8.31685626,20 7.48949482,20 C6.6623393,20 5.99167822,19.3284536 5.99167822,18.5 C5.99167822,17.6715464 6.6623393,17 7.48949482,17 C8.31685626,17 8.98751734,17.6715464 8.98751734,18.5"
              id="Fill-14"
              fill="#FFFFFF"
            />
            <path
              d="M8.98751734,18 C8.98751734,18.8284536 8.31685626,19.5 7.48949482,19.5 C6.6623393,19.5 5.99167822,18.8284536 5.99167822,18 C5.99167822,17.1715464 6.6623393,16.5 7.48949482,16.5 C8.31685626,16.5 8.98751734,17.1715464 8.98751734,18 Z"
              id="Stroke-16"
              stroke="currentColor"
            />
            <path
              d="M8.98751734,23.4998969 C8.98751734,24.3284075 8.31685626,25 7.48949482,25 C6.6623393,25 5.99167822,24.3284075 5.99167822,23.4998969 C5.99167822,22.6713863 6.6623393,22 7.48949482,22 C8.31685626,22 8.98751734,22.6713863 8.98751734,23.4998969"
              id="Fill-18"
              fill="#FFFFFF"
            />
            <path
              d="M8.98751734,23.4998969 C8.98751734,24.3284075 8.31685626,25 7.48949482,25 C6.6623393,25 5.99167822,24.3284075 5.99167822,23.4998969 C5.99167822,22.6713863 6.6623393,22 7.48949482,22 C8.31685626,22 8.98751734,22.6713863 8.98751734,23.4998969 Z"
              id="Stroke-20"
              stroke="currentColor"
            />
            <ellipse
              id="Oval"
              stroke="currentColor"
              cx="17.4757282"
              cy="17.5"
              rx="16.9757282"
              ry="17"
            />
          </g>
        </g>
      </g>
    </svg>
  </IconWrapper>
)

export default MenuIcon
