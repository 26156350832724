import styled from 'styled-components'

import media, { customMedia } from '../../common/MediaQueries'
import { border } from '../../common/mixins'

const StyledTools = styled.div`
  @media ${customMedia.maxPhone} {
    ${border}
    border-bottom: 0.125rem solid ${({ theme }) => theme.colors.valhalla};

    &::after,
    &::before {
      top: auto;
      bottom: -0.125rem;
    }
  }

  @media ${media.phone} {
    display: flex;
  }
`

const StyledToolsInfo = styled.div`
  margin-bottom: 1.75rem;

  @media ${media.phone} {
    display: flex;
    align-items: center;
    margin-right: auto;
    margin-bottom: 0;
    padding-right: 0.25rem;
  }
`

const StyledToolsDownloadLink = styled.div`
  a {
    border-left: 0;
  }
`

const StyledToolsContents = styled.div`
  button {
    display: flex;
    justify-content: space-between;

    @media ${customMedia.maxPhone} {
      width: 100%;
    }
  }
`

export {
  StyledTools,
  StyledToolsInfo,
  StyledToolsDownloadLink,
  StyledToolsContents,
}
