import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset-advanced'

import { transition } from '../common/mixins'
import media from '../common/MediaQueries'

const GlobalStyles = createGlobalStyle`
  ${reset}

  * {
    user-select: auto;
  }

  html {
    font-size: 16px;
  }

  body {
    font-family: 'Gotham', sans-serif;
    line-height: 1.375;
    color: #000;
    font-weight: 400;
  }

  body.is-hidden {
    overflow: hidden;
  }

  iframe,
  img {
    display: block;
    max-width: 100%;
  }

  p a:not([class]) {
    color: ${({ theme }) => theme.colors.teal};
    ${transition}

    &:hover {
      color: ${({ theme }) => theme.colors.navyBlue};
    }
  }

  @media ${media.phone} {
    main {
      margin-top: 5rem;
    }
  }
`

export default GlobalStyles
