import React from 'react'

import { StyledLogo, StyledLogoLabel, StyledLogoIcon } from './styles'

const LOGO_LINK_SRC = 'https://seswater.co.uk/'

const Logo = () => (
  <StyledLogo href={LOGO_LINK_SRC} target="_blank" rel="noopener noreferrer">
    <StyledLogoIcon />
    <StyledLogoLabel>SES Water</StyledLogoLabel>
  </StyledLogo>
)

export default Logo
