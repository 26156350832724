import styled, { css } from 'styled-components'

export const transition = ({
  property = 'all',
  duration = '270ms',
  timingFunction = 'ease-in-out',
}) => css`
  transition: ${property} ${duration} ${timingFunction};
`

export const IconWrapper = styled.span`
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  svg {
    width: ${({ width }) => `${width || 1}em`};
    height: 1em;
    font-size: ${({ fontSize }) => `${fontSize || 1}rem`};
    fill: currentColor;
  }
`

export const border = css`
  position: relative;
  &::after,
  &::before {
    position: absolute;
    content: '';
    top: -0.125rem;
    width: 1.25rem;
    height: 0.125rem;
    background-color: ${({ theme }) => theme.colors.valhalla};
  }
  &::before {
    left: -1.25rem;
  }
  &::after {
    right: -1.25rem;
  }
`

export function transitionDelay() {
  let styles = ''

  for (let i = 0; i < 10; i += 1) {
    styles += `
      .circles-icons #icon-${i} path {
         transition-delay: ${i * 250}ms;
       }
     `
  }

  return css`
    ${styles}
  `
}
