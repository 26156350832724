import React from 'react'

import { IconWrapper } from '../../common/mixins'

const DownloadIcon = () => (
  <IconWrapper>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35px"
      height="35px"
      viewBox="0 0 35 35"
      version="1.1"
    >
      <g
        id="Mobile"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="SES-Water-AR-Mobile"
          transform="translate(-262.000000, -228.000000)"
          stroke="currentColor"
        >
          <g id="Group-39" transform="translate(262.000000, 228.000000)">
            <g
              id="Group-17-Copy"
              transform="translate(9, 7)"
              strokeLinecap="round"
              className="arrow"
            >
              <g id="Group-4">
                <polygon
                  id="Stroke-1"
                  points="4.25 8.16933638 8.925 13.4210526 13.6 8.16933638 11.3085826 8.16933638 11.3085826 0 6.5414174 0 6.5414174 8.16933638"
                />
                <polyline
                  id="Stroke-3"
                  strokeLinejoin="round"
                  points="17 13.4210526 17 17 0 17 0 13.4210526"
                />
              </g>
            </g>
            <circle id="Oval-Copy-3" cx="17.5" cy="17.5" r="17" />
          </g>
        </g>
      </g>
    </svg>
  </IconWrapper>
)

export default DownloadIcon
