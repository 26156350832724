import React from 'react'

import { StyledContents, StyledContentsLabel } from './styles'
import MenuIcon from '../Icons/MenuIcon'
import DownloadIcon from '../Icons/DownloadIcon'
import { Typo8 } from '../Typography'
import media from '../../common/MediaQueries'
import { matchMedia } from '../../common/utils'

const Contents = ({ url, label, variant }) => {
  const abovePhone = matchMedia(`${media.phone}`).matches

  return (
    <StyledContents
      href={`#${url}`}
      to={url}
      spy
      smooth
      offset={abovePhone ? -80 : -72}
      delay={100}
    >
      <StyledContentsLabel>
        <Typo8>{label}</Typo8>
      </StyledContentsLabel>
      {variant === 'menu' ? (
        <MenuIcon />
      ) : ''}
      {variant === 'download' ? (
        <DownloadIcon />
      ) : ''}
    </StyledContents>
  )
}

export default Contents
