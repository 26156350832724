import styled, { css } from 'styled-components'

import { transition } from '../../../common/mixins'

const StyledSimpleLinkIcon = styled.div`
  ${({ isRight }) =>
    isRight &&
    css`
      transform: rotate(90deg);
    `}
`

const StyledSimpleLink = styled.a`
  ${transition}
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.valhalla};

  ${StyledSimpleLinkIcon} + p,
  p + ${StyledSimpleLinkIcon} {
    margin-left: 0.75rem;
  }

  svg {
    font-size: 1.5rem;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.teal};
  }

  ${({ isUnderlined }) =>
    isUnderlined &&
    css`
      ${transition}
      display: inline-block;
      text-decoration: none;
      background-image: linear-gradient(
        ${({ theme }) => theme.colors.white},
        ${({ theme }) => theme.colors.white}
      );
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: 0% 1px;
      padding-bottom: 0px;

      &:hover {
        background-size: 100% 1px;
      }

      p {
        color: ${({ theme }) => theme.colors.white};
      }
    `}
`

export { StyledSimpleLink, StyledSimpleLinkIcon }
