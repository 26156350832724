import styled from 'styled-components'
import { Link } from 'react-scroll'

import { transition, border} from '../../common/mixins'
import media, { customMedia } from '../../common/MediaQueries'

const StyledContents = styled(Link)`
  position: relative;
  color: ${({ theme }) => theme.colors.valhalla};
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  ${transition}

  @media ${customMedia.maxPhone} {
    border-top: 0.125rem solid ${({ theme }) => theme.colors.valhalla};
    ${border}
  }

  @media ${media.phone} {
    width: 15.125rem;
    border: 0.125rem solid ${({ theme }) => theme.colors.valhalla};
    border-top: 0;
    padding: 1.25rem;
    height: 5rem;

    &:hover {
      color: ${({ theme }) => theme.colors.teal};
      border-color: ${({ theme }) => theme.colors.teal};
      svg {
        color: ${({ theme }) => theme.colors.teal};
      }
    }
  }

  svg {
    ${transition}
    font-size: 2.1875rem;
    color: ${({ theme }) => theme.colors.valhalla};
  }
`

const StyledContentsLabel = styled.div`
  margin-right: 0.75rem;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`

export { StyledContents, StyledContentsLabel }
