import React, { useCallback } from 'react'
import { animateScroll as scroll } from "react-scroll"

import {
  StyledFooter,
  StyledFooterInner,
  StyledFooterCopy,
  StyledFooterCta,
  StyledFooterDesign,
  StyledFooterBack,
} from './styles'

import { Heading5 } from '../Typography'
import SimpleLink from '../Links/SimpleLink'

const Footer = () => {
  const scrollTop = useCallback(e => {
    e.preventDefault()

    scroll.scrollToTop({
      duration: 650,
      smooth: "linear",
    })
  }, [])

  return (
    <StyledFooter>
      <StyledFooterInner>
        <StyledFooterCopy>
          <Heading5 color="valhalla" fontWeight="medium">
            &copy; SES Water, All rights reserved
          </Heading5>
        </StyledFooterCopy>
        <StyledFooterCta>
          <SimpleLink
            url="https://seswater.co.uk/"
            label="seswater.co.uk"
            target="_blank"
            rel="noopener noreferrer"
            iconVariant="arrow-right"
          />
        </StyledFooterCta>
        <StyledFooterDesign>
          <SimpleLink
            url="https://www.luminous.co.uk/"
            label="Design by Luminous"
            typoVariant="heading4"
          />
        </StyledFooterDesign>
        <StyledFooterBack>
          <SimpleLink
            url="#"
            label="Back to top"
            iconVariant="arrow-top"
            onClick={scrollTop}
          />
        </StyledFooterBack>
      </StyledFooterInner>
    </StyledFooter>
  )
}

export default Footer
