import styled from 'styled-components'

import theme from '../../themes/theme'
import DynamicComponent from '../../common/DynamicComponent'

const {
  heading1,
  heading2,
  heading3,
  heading4,
  heading5,
  heading6,
  heading7,
  heading8,
  heading9,
  heading10,
  heading11,
  heading12,
  heading13,
  heading14,
  heading15,
  heading16,
  heading17,
  typo1,
  typo2,
  typo3,
  typo4,
  typo5,
  typo6,
  typo7,
  typo8,
  typo9,
  typo10,
  typo11,
  typo12,
  typo13,
  typo14,
  typo15,
  typo16,
  typo17,
  typo18,
} = theme.textStyles

const createTypoComponent = (props) => {
  const TypoComponent = styled(DynamicComponent)``

  TypoComponent.defaultProps = {
    ...props,
  }

  return TypoComponent
}

export const Heading1 = createTypoComponent(heading1)
export const Heading2 = createTypoComponent(heading2)
export const Heading3 = createTypoComponent(heading3)
export const Heading4 = createTypoComponent(heading4)
export const Heading5 = createTypoComponent(heading5)
export const Heading6 = createTypoComponent(heading6)
export const Heading7 = createTypoComponent(heading7)
export const Heading8 = createTypoComponent(heading8)
export const Heading9 = createTypoComponent(heading9)
export const Heading10 = createTypoComponent(heading10)
export const Heading11 = createTypoComponent(heading11)
export const Heading12 = createTypoComponent(heading12)
export const Heading13 = createTypoComponent(heading13)
export const Heading14 = createTypoComponent(heading14)
export const Heading15 = createTypoComponent(heading15)
export const Heading16 = createTypoComponent(heading16)
export const Heading17 = createTypoComponent(heading17)
export const Typo1 = createTypoComponent(typo1)
export const Typo2 = createTypoComponent(typo2)
export const Typo3 = createTypoComponent(typo3)
export const Typo4 = createTypoComponent(typo4)
export const Typo5 = createTypoComponent(typo5)
export const Typo6 = createTypoComponent(typo6)
export const Typo7 = createTypoComponent(typo7)
export const Typo8 = createTypoComponent(typo8)
export const Typo9 = createTypoComponent(typo9)
export const Typo10 = createTypoComponent(typo10)
export const Typo11 = createTypoComponent(typo11)
export const Typo12 = createTypoComponent(typo12)
export const Typo13 = createTypoComponent(typo13)
export const Typo14 = createTypoComponent(typo14)
export const Typo15 = createTypoComponent(typo15)
export const Typo16 = createTypoComponent(typo16)
export const Typo17 = createTypoComponent(typo17)
export const Typo18 = createTypoComponent(typo18)
