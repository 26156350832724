import styled from 'styled-components'

import Inner from '../Inner'
import media, { customMedia } from '../../common/MediaQueries'

const StyledFooter = styled.footer`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 3.625rem 0 2.5rem;

  @media ${media.phone} {
    display: flex;
    align-items: center;
    min-height: 7.5rem;
    padding: 3rem 0;
  }
`

const StyledFooterInner = styled(Inner)`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${media.phone} {
    flex-direction: row;
  }
`

const StyledFooterCopy = styled.div`
  @media ${customMedia.maxPhone} {
    order: 4;
    text-align: center;
  }

  @media ${media.phone} {
    display: flex;
    align-items: center;

    margin-right: 1rem;
  }

  @media ${media.tablet} {
    margin-right: 3.5rem;
  }
`

const StyledFooterCta = styled.div`
  @media ${customMedia.maxPhone} {
    order: 2;
    margin-bottom: 3.125rem;
  }

  @media ${media.phone} {
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }

  @media ${media.tablet} {
    margin-right: 3.5rem;
  }
`

const StyledFooterDesign = styled.div`
  color: ${({ theme }) => theme.colors.white};

  @media ${customMedia.maxPhone} {
    order: 3;
    margin-bottom: 3.75rem;
  }

  @media ${media.phone} {
    margin-right: 1rem;
  }

  @media ${media.tablet} {
    margin-right: 3.5rem;
  }
`

const StyledFooterBack = styled.div`
  @media ${customMedia.maxPhone} {
    margin-bottom: 3.125rem;
  }

  @media ${media.phone} {
    margin-left: auto;
  }
`

export {
  StyledFooter,
  StyledFooterInner,
  StyledFooterCopy,
  StyledFooterCta,
  StyledFooterDesign,
  StyledFooterBack,
}
